import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { SelectOption } from '@platform/types';

import { ConsoleLogHelper } from '@emma-helpers/console-log.helper';
import { NgSelectComponent } from '@ng-select/ng-select';
import { get, noop } from 'lodash';
import { takeUntil } from 'rxjs/operators';

import { EMMAFormElementComponent } from './emma-form-element.component';
import { validatorNumberRange, validatorEmailList } from '@emma-helpers/emma-validators.helper';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

type ItemsToStringCallback = (item: any) => string;
type IconCallback = (item: any) => string | Array<string>;
type LabelCallback = (item: any) => string;

const EMMA_SELECT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => EMMASelectComponent),
  multi: true,
};

const EMMA_SELECT_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => EMMASelectComponent),
  multi: true,
};

@Component({
  selector: 'emma-select',
  templateUrl: './emma-select.component.html',
  providers: [
    EMMA_SELECT_CONTROL_VALUE_ACCESSOR,
    EMMA_SELECT_VALIDATOR,
    {
      provide: EMMAFormElementComponent,
      useExisting: EMMASelectComponent,
    },
  ],
})
export class EMMASelectComponent
  extends EMMAFormElementComponent
  implements OnInit, OnChanges, ControlValueAccessor, Validator
{
  @ViewChild(NgSelectComponent, { static: false }) select!: NgSelectComponent;
  @ViewChild('popover', { static: false }) popover?: NgbPopover;

  @Input() debugMode = false;
  /**
   * Like ng-select
   */

  @Input() addText = $localize`Añadir`; // --> addTagText
  @Input() allowAdd?: boolean; // --> addTag
  @Input() clearAllText = $localize`Limpiar`;
  @Input() clearOnBackspace?: boolean;
  @Input() clearable?: boolean;
  // Whether to close the menu when a value is selected. Default true.
  @Input() closeOnSelect?: boolean;
  // Clears search input when item is selected. Default true. Default false when closeOnSelect is false
  @Input() clearSearchOnAdd?: boolean;
  // Set the dropdown position on open. Default auto.
  @Input() dropdownPosition: 'bottom' | 'top' | 'auto' = 'auto';
  @Input() groupBy?: string;
  @Input() selectableGroup?: boolean;
  @Input() selectableGroupAsModel?: boolean;
  // Oculta los valores ya seleccionados, útil sobre todo para selectores de tipo chips
  @Input() hideSelected: boolean;
  @Input() isOpen?: boolean;
  @Input() items: any[] = [];
  @Input() defaultItems: SelectOption[] = [];
  innerItems: SelectOption[] = [];
  @Input() labelForId?: string;
  @Input() loadingText = $localize`Cargando...`;
  @Input() multiple = false;
  @Input() isSimpleArray?: boolean;
  @Input() notFoundText = $localize`No encontrado/a`;
  @Input() searchable = true;
  @Input() typeToSearchText = $localize`Buscar...`;

  @Input() valueKey = 'value'; // --> bindValue
  @Input() labelKey = 'label'; // --> bindLabel
  @Input() iconKey?: string;
  @Input() descriptionKey?: string;
  @Input() descriptionCallback!: LabelCallback;
  @Input() iconCallback!: IconCallback;
  @Input() labelCallback!: LabelCallback;
  @Input() valueCallback!: LabelCallback;

  /**
   * Extras
   */
  @Input() allowEmptyString = false;
  @Input() closeOnRemoveItem = true;
  @Input() color?: string;
  // Si no es un selector múltiple, aunque sea > 1, se asume 1
  @Input() defaultFirstValues = 0;
  @Input() dropPanelRight = false;
  @Input() dropPanelFullWidth = false;
  @Input() isFullWidth = true;
  @Input() removableItems = false;
  @Input() removableItemKey?: string;
  @Input() showChips?: boolean;
  @Input() canRemoveChips?: boolean;
  @Input() allowSelectAll?: boolean;
  @Input() allowDeselectAll?: boolean;
  @Input() selectAllButtonLabel = $localize`Seleccionar todo`;
  @Input() deselectAllButtonLabel = $localize`Deseleccionar todo`;
  @Input() allowLoadMore?: boolean;

  // Validation options
  /**
   * @description
   * If single selection: string length
   * If multiple selection: array/set length
   */
  @Input() type = 'text';
  @Input() minlength = 0;
  @Input() maxlength = Infinity;
  @Input() maxSelectedItems?: number;

  @Input() preLabel?: string;
  @Input() multiLabel: ItemsToStringCallback;
  @Input() maxMultiLabel = 5;
  @Input() unit?: string;
  @Input() units?: string;

  @Input() selectClass = `ng-select-drop-auto-width`;

  @Input()
  // get accessor
  get value(): any {
    return this.innerValue;
  }

  // set accessor including call the onchange callback
  set value(value: any) {
    if (!this.allowEmptyString && value === '') {
      value = null;
    }
    if (value !== this.innerValue) {
      this.innerValue = value;
      // Soporte para Array con selección simple
      if (!this.multiple && this.isSimpleArray && !Array.isArray(value)) {
        this.onChangeCallback([value]);
      } else {
        this.onChangeCallback(value);
      }
    }
    if (this.multiple) {
      this.updateMultilabel();
    }
  }

  @Input() showHelpMessageAtError = false;
  @Input() helpMessageAtError = '';

  @Output() $open = new EventEmitter<any>();
  @Output() $close = new EventEmitter<any>();
  @Output() $search = new EventEmitter<any>();
  @Output() $clear = new EventEmitter<any>();
  @Output() $add = new EventEmitter<any>();
  @Output() $scroll = new EventEmitter<any>();
  @Output() $scrollToEnd = new EventEmitter<any>();
  @Output() $loadMore = new EventEmitter<any>();
  /**
   * Quitado un elemento de la selección
   */
  @Output() $remove = new EventEmitter<any>();
  /**
   * Solicitud de eliminado de un elemento del select
   */
  @Output() $removeItem = new EventEmitter<any>();
  @Output() $option = new EventEmitter<any>();

  _selectClass: string[] = [];
  _containerSelectClass: string[] = [];
  isValid = true;

  public virtualScroll = false;
  public multiLabelCache = '';
  private innerValue: any = null;
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  private logger = new ConsoleLogHelper('EMMASelectComponent');

  title = '';

  constructor() {
    super();
    this.multiLabel = (items: any[]) => {
      if (items.length > this.maxMultiLabel && this.units) {
        return `${items.length} ${items.length === 1 ? this.unit || this.units : this.units}`;
      } else {
        return items.map((item) => ('object' === typeof item ? get(item, this.labelKey) : item)).join(', ');
      }
    };
    this.hideSelected = Boolean(this.showChips);
  }

  override updateClasses(): void {
    this._selectClass = [
      'ng-select',
      this.selectClass,
      this.showChips ? 'ng-select-tags' : '',
      this.canRemoveChips ? 'ng-select-tags-removable' : '',
      this.isFullWidth ? 'ng-select-form-control' : '',
      this.removableItems || this.removableItemKey ? 'ng-select-removable' : '',
      this.dropPanelRight ? 'ng-select-drop-panel-right' : '',
      this.dropPanelFullWidth ? 'ng-select-drop-panel-full-width' : '',
      this.color ? `ng-select-btn-${this.color}` : 'ng-select-btn-metal',
    ];

    this._containerSelectClass = ['ngSelect-helpMessage-container', this.isFullWidth ? 'fullwidth' : ''];
  }

  override ngOnInit(): void {
    this.updateClasses();
    if (this.closeOnSelect === undefined) {
      this.closeOnSelect = !this.multiple;
    }
    this.$blur.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.onTouchedCallback());
  }

  override ngOnChanges(changes: SimpleChanges): void {
    // Soporte para items que son strings
    if ('items' in changes || 'defaultItems' in changes) {
      this.updateItems();
      this.setTitle();
    }
    if ('selectClass' in changes || 'showChips' in changes) {
      this.updateClasses();
    }
    if ('allowAdd' in changes) {
      if (this.allowAdd) {
        this.searchable = true;
      }
    }
    if (this.defaultFirstValues) {
      this.checkDefault();
    }
    if ('helpMessageAtError' in changes) {
      this.checkIfPopoverHelp();
    }
  }

  private updateItems() {
    this.updateInnerItems();
    if (this.multiple) {
      this.updateMultilabel();
    }
    this.virtualScroll = Boolean(this.items && this.items.length > 100);
  }

  private updateMultilabel(): void {
    if (Array.isArray(this.value)) {
      const multiLabel = this.multiLabel(this.value.map(this.findItemByValue));
      if (multiLabel !== this.multiLabelCache) {
        this.multiLabelCache = multiLabel;
      }
    }
  }

  private updateInnerItems(): void {
    if (!this.items || !this.items.length) {
      this.innerItems = this.defaultItems;
    } else {
      this.innerItems = this.items.map((item) => {
        if ('object' !== typeof item) {
          return { value: item, label: item, description: item };
        } else {
          // Value
          let value;
          if (this.valueCallback) {
            value = this.valueCallback(item);
          } else if (this.valueKey) {
            value = get(item, this.valueKey);
          }
          // Label
          let label;
          if (this.labelCallback) {
            label = this.labelCallback(item);
          } else if (this.labelKey) {
            label = get(item, this.labelKey);
          }
          // Description
          let description;
          if (this.descriptionCallback) {
            description = this.descriptionCallback(item);
          } else if (this.descriptionKey) {
            description = get(item, this.descriptionKey);
          }
          // Icons
          let icons: string[] | undefined;
          if (this.iconCallback || this.iconKey) {
            let itemIcons: string | Array<string> | undefined;
            if (this.iconCallback) {
              itemIcons = this.iconCallback(item);
            } else if (this.iconKey) {
              itemIcons = get(item, this.iconKey);
            }
            if (itemIcons) {
              icons = Array.isArray(itemIcons) ? itemIcons : [itemIcons];
            }
          }
          // Removable
          let isRemovable = this.removableItems;
          if (this.removableItemKey) {
            isRemovable = Boolean(get(item, this.removableItemKey));
          }
          // Group by
          let group;
          if (this.groupBy) {
            group = get(item, this.groupBy);
          }
          // Label for ID
          let id;
          if (this.labelForId) {
            id = get(item, this.labelForId);
          }
          return {
            value,
            label,
            description,
            icons,
            isRemovable,
            group,
            id,
          };
        }
      });
      this.innerItems = this.innerItems.concat(this.defaultItems);
    }
  }

  public onChange(value: any): void {
    this.value = value;
    this.$change.emit(value);
  }

  public onSelectAll(): void {
    const options = this.innerItems.slice(0);
    this.onChange(options.map((item) => item.value));
    this.onOptionChange(options);
  }

  public onClearAll(): void {
    if (this.innerItems.length) {
      const options = this.innerItems.slice(0, this.defaultFirstValues);
      this.onChange(options.map((item) => item.value));
      this.onOptionChange(options);
    } else {
      this.onChange([]);
      this.onOptionChange([]);
    }
  }

  checkDefault(): void {
    if (this.defaultFirstValues && !this.disabled && !this.innerValue && this.innerItems.length) {
      if (this.multiple) {
        const options = this.innerItems.slice(0, this.defaultFirstValues);
        this.onChange(options.map((item) => item.value));
        this.onOptionChange(options);
      } else {
        const option = this.innerItems[0];
        this.onChange(option?.value);
        this.onOptionChange(option);
      }
    }
  }

  private findItemByOption = (option: SelectOption) => {
    const item =
      option && this.items.find((itm) => get(itm, this.valueKey) === option.value || itm === option.value);
    if (item) {
      return item;
    } else if (this.allowAdd) {
      const value = option.label ?? option;
      this.appendNewItemAndOption(value);
      return value;
    } else {
      this.logger.warn('Item not found for option', { option });
      return option;
    }
  };

  private findItemByValue = (value: unknown) => {
    const item = this.items.find((itm) => get(itm, this.valueKey) === value || itm === value);
    if (item) {
      return item;
    } else if (this.allowAdd) {
      this.appendNewItemAndOption(value);
      return value;
    } else {
      this.logger.warn('Item not found for value', { value });
      return value;
    }
  };

  onRemoveItem(ev: MouseEvent, value: unknown): void {
    ev.preventDefault();
    ev.stopPropagation();
    if (this.closeOnRemoveItem) {
      this.select.close();
    }
    const item = this.findItemByValue(value);
    this.$removeItem.emit(item);
  }

  onOptionChange(option: SelectOption | SelectOption[]): void {
    if (this.debugMode) {
      this.logger.log({ name: '(change)', value: option });
    }
    if (Array.isArray(option)) {
      const itemOpts = option.map(this.findItemByOption);
      this.title = this.multiLabel(itemOpts);
      this.$option.next(itemOpts);
    } else {
      this.title = option.label;
      this.$option.next(this.findItemByOption(option));
    }
  }

  appendNewItemAndOption(value: unknown) {
    const isDefaultOption = this.defaultItems.find((item) => item.label === value || item.value === value);
    if (this.allowAdd && !isDefaultOption) {
      // en modo agregar el nuevo elemento en onchanges es undefined
      if (Array.isArray(this.items) && !this.items.includes(value)) {
        this.items.push(value);
        this.updateInnerItems();
      }
      /** Al añadir una nueva opción el valor del select es undefined, hay que forzar la actualización */
      if (Array.isArray(this.value) && !this.innerValue.includes(value)) {
        this.onChange([...this.innerValue.filter((item: any) => item !== undefined), value]);
      }
      if (!Array.isArray(this.value) && !this.innerValue !== value) {
        this.onChange(value);
      }
    }
  }

  onOptionAdd(option: SelectOption | string): void {
    if (this.debugMode) {
      this.logger.log({ name: '(add)', value: option });
    }
    const label = (option as SelectOption).label ?? option;
    this.appendNewItemAndOption(label);
    if ('string' === typeof option) {
      this.$add.next(option);
    } else {
      this.$add.next(option.label);
    }
  }

  onOptionRemove(option: SelectOption): void {
    if (this.debugMode) {
      this.logger.log({ name: '(remove)', value: option });
    }
    this.$remove.next(option);
  }

  onChipRemove(ev: MouseEvent, option: SelectOption): void {
    if (this.debugMode) {
      this.logger.log({ name: '(chipRemove)', value: option });
    }
    ev.preventDefault();
    ev.stopPropagation();
    if (Array.isArray(this.value) && this.value.length) {
      const value = this.value.filter((val: unknown) => val !== option.value);
      this.onChange(value);
      this.onOptionChange(option);
    }
  }

  onBlur($event: FocusEvent): void {
    if (this.debugMode) {
      this.logger.log({ name: '(blur)', value: $event });
    }
    this.$blur.emit($event);
  }
  onClear($event: undefined): void {
    if (this.debugMode) {
      this.logger.log({ name: '(clear)', value: $event });
    }
    this.$clear.emit($event);
  }
  onClose($event: undefined): void {
    if (this.debugMode) {
      this.logger.log({ name: '(close)', value: $event });
    }
    this.$close.emit($event);
  }
  onFocus($event: FocusEvent): void {
    if (this.debugMode) {
      this.logger.log({ name: '(focus)', value: $event });
    }
    this.$focus.emit($event);
  }
  onOpen($event: undefined): void {
    if (this.debugMode) {
      this.logger.log({ name: '(open)', value: $event });
    }
    this.$open.emit($event);
  }
  onSearch($event: { items: unknown[]; term: string }): void {
    if (this.debugMode) {
      this.logger.log({ name: '(search)', value: $event });
    }
    this.$search.emit($event);
  }
  onScroll($event: { start: number; end: number }): void {
    if (this.debugMode) {
      this.logger.log({ name: '(scroll)', value: $event });
    }
    this.$scroll.emit($event);
  }
  onScrollToEnd($event: undefined): void {
    if (this.debugMode) {
      this.logger.log({ name: '(scrollToEnd)', value: $event });
    }
    this.$scrollToEnd.emit($event);
  }
  onLoadMore($event: undefined): void {
    if (this.debugMode) {
      this.logger.log({ name: '(loadMore)', value: $event });
    }
    this.$loadMore.emit($event);
  }

  private setTitle() {
    if (this.value) {
      if (this.multiple && Array.isArray(this.value)) {
        const multiLabel = this.multiLabel(this.value.map(this.findItemByValue));
        this.title = multiLabel;
      } else {
        this.title = this.findItemByValue(this.value)?.label;
      }
    }
  }

  // From ControlValueAccessor interface
  writeValue(value: any): void {
    if (!this.allowEmptyString && value === '') {
      value = null;
    }
    // Soporte para Array como selección simple
    if (!this.multiple && Array.isArray(value)) {
      // Si el modelo de entrada es un Array, devolvamos un Array
      this.isSimpleArray = true;
      if (value[0] !== this.innerValue) {
        this.innerValue = value[0];
      }
    } else if (value !== this.innerValue) {
      this.innerValue = value;
      if (this.multiple) {
        this.updateMultilabel();
      }
    }
    this.setTitle();
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  checkIfPopoverHelp(): void {
    if (this.popover && this.showHelpMessageAtError) {
      this.popover.ngbPopover = this.helpMessageAtError;
      this.isValid ? this.popover.close() : this.popover.open();
    }
  }

  // From Validator interface
  override validate: ValidatorFn = (control: AbstractControl) => {
    const validators: ValidatorFn[] = [];
    if (this.required) {
      validators.push(Validators.required);
      if (Array.isArray(control.value)) {
        validators.push(Validators.minLength(1));
      }
    }
    if (this.maxlength < Infinity) {
      validators.push(Validators.maxLength(this.maxlength));
    }
    const maxSelectedItems = this.maxSelectedItems || 0;
    if (
      this.multiple &&
      Array.isArray(this.value) &&
      maxSelectedItems > 0 &&
      this.value.length >= maxSelectedItems
    ) {
      validators.push(validatorNumberRange(0, maxSelectedItems));
    }
    if (this.minlength > 0) {
      validators.push(Validators.minLength(this.minlength));
    }
    if (this.type === 'emailList') {
      validators.push(validatorEmailList);
    }
    if (this.type === 'email') {
      validators.push(Validators.email);
    }
    if (this.validateFn) {
      validators.push(this.validateFn);
    }
    if (validators.length) {
      const validator = Validators.compose(validators);
      const result = validator ? validator(control) : null;
      this.isValid = !result;
      this.checkIfPopoverHelp();
      return result;
    }
    return null;
  };
}
