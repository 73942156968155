<emma-missing-alert
    *ngIf="!hasData"
    [missing]="missing || portletTitle"
    class="emmaMissingAlert"
></emma-missing-alert>

<div
    #portlet
    [ngClass]="{
        'm-portlet m-portlet--fit': true,
        blockedPortlet: !hasData,
        'm-portlet--full-height': hasData,
        'm-portlet--no-head': !portletTitle,
        'm-portlet--bordered-semi': !sticky,
        'm-portlet--sticky': sticky,
        'm-portlet--sticky__filterbar': stickyPosition === 'filterbar',
        'm-portlet--sticky__top': stickyPosition === 'top',
        'm-portlet--wizzard': hasWizard,
        'm-portlet--with-description': description,
        'm--no-bottom-margin': !showMargin
    }"
    [class]="class"
>
    <emma-loading *ngIf="isLoading || isError" [error]="isError"></emma-loading>
    <div *ngIf="portletTitle"
      [ngClass]="{
        'm-portlet__head': true,
        'm-portlet__head--no-padding': !showPadding
      }"
    >
        <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
                <h3 *ngIf="!href" class="m-portlet__head-text">
                    <button
                        *ngIf="backButton"
                        class="m-btn--back"
                        emmaButton
                        [isCircle]="true"
                        [hasIcon]="true"
                        [onlyIcon]="true"
                        [noBg]="true"
                        color="brand"
                        (click)="goBack()"
                        ngbPopover="Volver atrás"
                        i18n-ngbPopover
                    >
                        <i class="la la-mail-reply" aria-hidden="true"></i>
                    </button>
                    <span [ngbPopover]="titlePopover" placement="top-left">{{ portletTitle }}
                      <emma-info-icon
                                [helpText]="helpText"
                                [helpLink]="helpLink"
                                [helpLinkLabel]="helpLinkLabel"
                      ></emma-info-icon>
                    <br/>
                    </span>

                    <small *ngIf="description">{{ description }}</small>
                </h3>
                <h3 *ngIf="href" class="m-portlet__head-text">
                    <a
                        href="{{ href }}"
                        target="_self"
                        class="m-link m-link--emmadarkblue"
                        [ngbPopover]="titlePopover"
                        placement="top-left"
                    >
                        {{ portletTitle }}
                        <i class="la la-mail-forward" aria-hidden="true"></i>
                    </a>
                    <small *ngIf="description">{{ description }}</small>
                </h3>
            </div>
        </div>
        <div class="m-portlet__head-tools">
            <ng-content select=".emma-portlet-head-tools"></ng-content>
        </div>
    </div>
    <div
        [ngClass]="{
            'm-portlet__body': true,
            'm-portlet__body--no-padding': !showPadding
        }"
    >
        <div
            [ngClass]="{
                'm-form': hasForm,
                'm-form--fixed-width-xl': hasForm && sticky && !hasWizard && !hasSidebar,
                'm-form--sidebar': hasForm && !hasWizard && hasSidebar,
                'mx-auto': hasForm && !hasWizard
            }"
        >
            <ng-content></ng-content>
        </div>
    </div>
</div>
