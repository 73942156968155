import { Component, Input } from '@angular/core';
import { sectionAnimations } from '@platform/helpers/animations.helper';

@Component({
  selector: 'emma-form-block',
  templateUrl: './emma-form-block.component.html',
  animations: [...sectionAnimations],
})
export class EMMAFormBlockComponent {
  @Input() isNewFeature = false;
  @Input() isFirst = false;
  @Input() isLast = false;
  @Input() headTitle = '';
  @Input() titleIcon = '';
  @Input() description = '';
  @Input() descriptionHighlight = false;
  @Input() descriptionHighlightText = '';

  @Input() descriptionLink = false;
  @Input() descriptionHelpLink = 'https://docs.emma.io';
  @Input() descriptionHelpLinkText = $localize`Saber más`;

  @Input() copyContent = '';

  @Input() powerAdminBlock = '';

  @Input() titleModalVideoEmbed = '';
  @Input() youtubeVideoEmbed = '';
}
